import axios from "axios";
import { reactive, toRefs, defineComponent, computed } from "vue";
import { parseTime } from "@/utils/index.js";
import { translateTitle } from "@/utils/i18n";
import { useStore } from "vuex";
export default defineComponent({
  name: "S1Track",
  components: {},
  props: {
    trackingNumberList: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const $store = useStore();
    const state = reactive({
      centerDialogVisible: false,
      //   展示状态 1 正常，0 加载中 2 没有搜索到
      state: 0,
      //   弹框数据
      data: null,
      params: null,
      type: computed(() => {
        return $store.getters["settings/language"];
      })
    });

    const show = () => {
      // 判断当前语言环境 zh or en  如何是en，则组合数据二次发起请求，调用谷歌翻译
      state.centerDialogVisible = true; //  将trackingNumberList，根据','拆分。赋值在params上

      state.params = props.trackingNumberList.split("\n");
      axios({
        method: "post",
        url: "https://portal.sengiexpress.com/api-v2/tracking/web",
        data: {
          trackingNumberList: state.params,
          type: 1
        }
      }).then(res => {
        state.data = res.data;
        setTimeout(() => {
          if (Object.keys(res.data).length === 0) {
            // 为空
            state.state = 2;
          } else {
            state.state = 1;
          }
        }, 1000);
      }).catch(() => {
        state.state = 3;
      });
    }; // 弹框关闭的回调事件


    const closeDialog = () => {
      state.centerDialogVisible = false;
      state.data = null;
      state.params = null;
      state.state = 0;
    };

    return {
      props,
      show,
      parseTime,
      closeDialog,
      ...toRefs(state),
      translateTitle
    };
  }

});